import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/database";
import {Button, Card, Container, Grid, Menu, Segment} from "semantic-ui-react";
import ContentEditor from "../../components/ContentEditor"
import {NavLink} from "react-router-dom";
import {ConfigContext} from "../../index";
import update from "immutability-helper";

function QuestionsPage({match}) {

    const {params} = match;
    const activeItem = params.path;

    const [items, setItems] = useState([]);

    useEffect(() => {

        firebase.database().ref("questions").orderByChild("createdAtI").get().then(function (snapshot) {
            if (snapshot.exists()) {
                let data = snapshot.val();
                let items = [];
                for (let key of Object.keys(data)) {
                    items.push({
                        ...data[key],
                        id: key,
                    })
                }
                setItems(items.sort((a, b) => a.createdAtI || 0 < b.createdAtI || 0));
            }
        });

    }, []);

    const deleteItem = i => {

        if (!window.confirm("Are you sure you want to remove this question?"))return;

        let item = items[i];

        firebase.database().ref("questions").child(item.id).remove().then(() => {
            setItems(update(items, {
                $splice: [[i, 1]]
            }))
        });

    }

    return (
        <>
            {items && items.map((item, i) => <Segment key={'item' + i}>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <h4>
                                    {item.name}
                                </h4>
                                <a href={"mailto:" + item.email}>{item.email}</a>
                            </Grid.Column>
                            <Grid.Column width={6} textAlign={"right"}>
                                {item.createdAt}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <pre>
                        {item.question}
                    </pre>
                    <Button size="tiny" color="red" basic className="pull-right" onClick={() => deleteItem(i)}>Verwijderen</Button>
                    <br style={{clear: "both"}} />
                </Segment>
            )}

        </>
    );
}

export default QuestionsPage;
