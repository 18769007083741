import React, {useState} from 'react';
import "firebase/auth";
import {ConfigContext} from "../../index";
import Message from "./Message";

function HomePage() {

    return (
        <>
            <p>
                Welcome to the Live Megawatt admin panel!
            </p>

            <br/><br/><br/>

            <ConfigContext.Consumer>
                {value => <Message config={value} />}
            </ConfigContext.Consumer>

        </>
    );
}

export default HomePage;
