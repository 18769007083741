import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Dropdown, Form, Header, Message} from "semantic-ui-react";
import firebase from "firebase/app";
import "firebase/auth";

function LaunchscreenNews() {

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {

        firebase.database().ref("newsitems").orderByChild("createdAtI")
            .get().then(function (snapshot) {
            if (snapshot.exists()) {
                let data = snapshot.val();
                let items = Object.keys(data).map(key => {
                    let item = data[key];
                    let date = item["createdAt"].substr(0, 10);
                    return {
                        key,
                        value: key,
                        text: date + " - " + item.subject,
                    }
                })
                items.sort((a, b) => b.createdAtI < a.createdAtI);
                setItems(items);
                if(selected !== null) setLoading(false);
            }
        });

    }, []);

    useEffect(() => {

        firebase.database().ref("newsitem-on-launchscreen")
            .get().then(function (snapshot) {
            if (snapshot.exists()) {
                setSelected(snapshot.val());
                if(items.length > 0) setLoading(false);
            }
        });

    }, []);

    const save = e => {

        e.preventDefault();
        setLoading(true);

        firebase.database().ref("newsitem-on-launchscreen")
            .set(selected).then(() => {
                reload();
        });

        return false;
    }

    const reload = () => {
        setLoading(true);
        fetch("https://betuwewind-back-end.livemegawatt.com/refresh-news").then(response => {
            setLoading(false);
            if (response.status !== 200) {
                alert("Something went wrong...");
            }
        })
    }

    return (
        <>

            <Button onClick={reload}>Nieuws verversen vanuit WordPress</Button>

            <br/><br/><br/>

            <Form loading={loading} onSubmit={save}>
                <Card fluid>
                    <Card.Content>
                        <h3>Nieuwsbericht weergegeven bij opstarten app:</h3>
                        <Dropdown placeholder="Select news item" fluid
                                  value={selected}
                                  onChange={(e, {value}) => setSelected(value)}
                                  selection options={
                            [
                                {
                                    key: '',
                                    text: 'Geen nieuwsbericht weergeven',
                                    value: '',
                                },
                                ...items,
                            ]
                        }/>
                        <br/><br/>
                        <Button primary>Opslaan</Button>

                    </Card.Content>
                </Card>

            </Form>


        </>
    );
}

export default LaunchscreenNews;
