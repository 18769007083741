import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Modal,} from "semantic-ui-react";
import "firebase/auth";
import firebase from "firebase";

function UserModal(props) {

    const {open, closeModal, user} = props;
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [type, setType] = useState("limited");

    useEffect(() => {

        if (user) {
            setEmail(user.email);
            setName(user.name);
            setType(user.type);
        } else {
            setEmail("");
            setName("");
            setType("limited");
        }

    }, [user]);

    const save = (e) => {

        e.preventDefault();

        if (!email || email === "") {
            window.alert("Please enter an e-mail address.")
            return false;
        }

        console.log(user, {
            createdAt: new Date(),
            name,
            type,
            email,
        });

        (user ? firebase.database().ref("users").child(user.id).update({
                updatedAt: new Date(),
                name,
                type,
            }) : firebase.database().ref("users").push().set({
                createdAt: new Date(),
                email,
                name,
                type,
            })
        ).then((data) => {
            // console.log(data);
            closeModal();
            setEmail("");
        }).catch(error => {
            // console.error(error);
            alert(error.message);
        })
    }

    return (
        <Modal
            open={open}
            onClose={() => closeModal()}>
            <Modal.Header>Add user</Modal.Header>
            <Modal.Content>

                <Form>
                    <Form.Input type="text" label="Name" placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}/>
                    <Form.Input type="text" label="E-mail address" placeholder="E-mail address"
                                value={email}
                                disabled={user}
                                onChange={(e) => setEmail(e.target.value)}/>

                    <Form.Group inline>
                        {["limited", "full", "admin"].map((t, i) =>
                            <Form.Field
                                key={"radio" + t}>
                                {i === 0 && <label>User type:</label>}
                                <Checkbox
                                    radio
                                    label={t}
                                    name='checkboxRadioGroup'
                                    value={t}
                                    checked={type === t}
                                    disabled={t === "admin" || type === "admin"}
                                    onChange={(e, {value}) => setType(value)}
                                />
                            </Form.Field>)}
                    </Form.Group>
                </Form>

            </Modal.Content>
            <Modal.Actions>
                <Button color='black'
                        onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button
                    content="Save"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={save}
                    positive
                />
            </Modal.Actions>


        </Modal>
    );
}

export default UserModal;
