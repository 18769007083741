import React, {useEffect, useState} from 'react';
import ContentEditor from "../../components/ContentEditor";
import firebase from "firebase";

function Message ({config}) {

    const [item, setItem] = useState(null);
    const path = "live-data/park/message";

    useEffect(() => {

        firebase.database().ref(path).get().then(function (snapshot) {
            if (snapshot.exists()) {
                let item = snapshot.val();
                setItem(item);
                console.log({item})
            }
        });

    }, []);


    return (config.home && config.home.indexOf("message") > -1) ? <>
        <h2>Message</h2>
        <em>Any texts or other content added below will be visible in the app on the top of the first
            page.</em>
        <ContentEditor path={path} item={item}/>
    </> : null;
}

export default Message;
