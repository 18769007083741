import React, {useEffect, useState} from 'react';
import {Button, Container, Grid, Header, Icon, Label, Table} from "semantic-ui-react";
import "firebase/auth";
import firebase from "firebase";
import UserModal from "./UserModal";

function UsersPage() {

    const [items, setItems] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [editUser, setEditUser] = useState(null);

    const closeModal = () => {
        setShowAddUserModal(false);
        setEditUser(null);
    }


    const removeUser = user => {
        if (window.confirm("Are you sure you want to remove this user?")) {
            firebase.database().ref("users").child(user.id).update({disabled: true});
        }
    }

    const resetPassword = user => {
        if (window.confirm("Are you sure you want to reset the passwor for this user?")) {
            firebase.database().ref("users").child(user.id).update({resetPassword: true});
        }
    }

    useEffect(() => {

        firebase.database().ref("users").orderByChild("email").on("value", (snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                let items = [];
                for (let key of Object.keys(data)) {
                    items.push({
                        ...data[key],
                        id: key,
                    })
                }
                setItems(items);
                // setItems(items.sort((a, b) => a.createdAtI || 0 < b.createdAtI || 0));
            }
        });

    }, []);

    const colors = {
        "full": "green",
        "admin": "black",
        "limited": "blue",
    }

    return (
        <>

            <UserModal open={showAddUserModal} closeModal={closeModal} user={editUser}/>

            <Button onClick={() => setShowAddUserModal(true)}>Add user</Button>

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        {/*<Table.HeaderCell>Added on</Table.HeaderCell>*/}
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map(item => !item.disabled && <Table.Row key={item.id}>
                        <Table.Cell>
                            {!item.firebaseUser && <Icon name="warning sign" color={"red"} title={"There was a problem creating this user account, please contact Live Megawatt."} />}
                        </Table.Cell>
                        <Table.Cell>{item.email}</Table.Cell>
                        <Table.Cell>{item.name || ""}</Table.Cell>
                        <Table.Cell>
                            <Label color={colors[item.type] || "grey"}>{item.type}</Label>
                        </Table.Cell>
                        {/*<Table.Cell></Table.Cell>*/}
                        <Table.Cell>
                            <Button circular icon="edit" color="orange" size="tiny"
                                    onClick={() => {
                                        setEditUser(item);
                                        setShowAddUserModal(true)
                                    }}/>
                            <Button circular icon="key" color="blue" size="tiny" title={"Reset Password"}
                                    onClick={() => resetPassword(item)} />
                            <Button circular icon="remove" color="red" size="tiny"
                                    onClick={() => removeUser(item)}
                            />
                        </Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>

        </>
    );
}

export default UsersPage;
